import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
/* Layout */
// import Layout from '@/layout'
const routes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
    meta:{keepAlive: true },
    // children: [
    //   {
    //     path: 'home',
    //     component: (resolve) => require(['@/views/HomeView'], resolve),
    //     name: '首页',
    //     meta: { title: '首页', icon: 'dashboard', noCache: true, affix: true }
    //   },
    // ]
  },
  {
    path: '/home',
    component: () => import('@/views/HomeView'),
    name: '首页',
    // meta: { title: '首页', icon: 'dashboard', noCache: true, affix: true }
    meta:{keepAlive: true },
  },
  // {
  //   path: '/home',
  //   // component: Layout,
  //   redirect: 'home',
  //   children: [
  //     {
  //       path: 'home',
  //       component: (resolve) => require(['@/views/HomeView'], resolve),
  //       name: '首页',
  //       meta: { title: '首页', icon: 'dashboard', noCache: true, affix: true }
  //     },
  //   ]
  // },
]

const router = new VueRouter({
  routes
})

export default router
