<template>
<div class="app-container">
  <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="90px">
    <el-form-item prop="companyName">
      <el-input v-model="queryParams.companyName">
      </el-input>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
      <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
    </el-form-item>
  </el-form>

  <el-table v-loading="loading" :data="enterpriseList" @selection-change="handleSelectionChange" boder>
    <el-table-column
            type="selection"
            width="55">
    </el-table-column>
    <el-table-column label="姓名" align="center" prop="name" ></el-table-column>
    <el-table-column label="地址" align="center" prop="address">
    </el-table-column>
    <el-table-column label="日期" align="center" prop="date" width="180" >
<!--      <template slot-scope="scope">-->
<!--        <span>{{ parseTime(scope.row.authorizationStartTime, '{y}-{m}-{d}') }}</span>-->
<!--      </template>-->
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="160">
      <template slot-scope="scope" >
        <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="handleUpdate(scope.row)"

        >查看</el-button>
        <!-- <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          @click="handleDelete(scope.row)"
        >删除</el-button> -->
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
  />

</div>
</template>
<script>
  export default {
    name: "HomeView",

    components: {},
    data() {
      return {
        number: 0,
        viewopen: false,
        showViewer: false,
        srcList: [],
        fileList: [],
        // 遮罩层
        loading: false,
        // 选中数组
        ids: [],
        sltIndex: 0,
        fname: '',
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 显示搜索条件
        showSearch: true,
        // 总条数
        total: 0,
        enterpriseList:[{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }],
        //已确权组织
        auditDeptList: [],
        //判断是否可编辑
        isDisabled: false,
        //组织
        deptList: [],
        //客户集合
        customerList: [],
        //状态集合
        statusList: [{value: '1', label: '暂存'}, {value: '2', label: '提交'}, {value: '3', label: '审核'}, {
          value: '4',
          label: '驳回'
        }, {value: '5', label: '作废'}],
        isSelected: false,
        // 弹出层标题
        title: "",
        // 是否显示弹出层
        open: false,
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          companyName: null

        },
        // 表单参数
        form: {},
        uploadList: [],
        // 表单校验
        rules: {
          companyName: [
            {required: true, message: "公司名称不能为空", trigger: "blur"}
          ],

        },
      };
    },
    created() {

    },
    computed: {
    },
    methods: {
      //搜索
      handleQuery(){

      },
      //重置
      resetQuery(){

      },
      //列表多选
      handleSelectionChange(){

      },
      getList(){

      },
      //修改
      handleUpdate(){

      },
    },
  }
</script>
